<div *ngIf="paymentSettings && paymentSettings.savvyPaySettings && paymentSettings.savvyPaySettings.accountHolderCode">
  <mat-card appearance="outlined" class="panel new-card" *ngIf="plans && plans.length > 0">
    <mat-card-title>
      <div fxLayout="row">
        <div fxFlex="100">
          <p class="mt-xs mb-xs">{{'Payment Plans' | translate}}</p>
        </div>
      </div>
    </mat-card-title>

    <mat-card-content>
      <ngx-datatable class="material" (select)="onSelect($event)" [selected]="selected" [selectionType]="'cell'" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [rows]="plans" (activate)="onActivate($event)">

        <ngx-datatable-column name="{{'Plan Name' | translate}}" [width]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            {{row.planName}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Payment Schedule' | translate}}" [width]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            {{row.paymentSchedule}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Payment Interval' | translate}}" [width]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            {{row.paymentInterval}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Remaining Sessions' | translate}}" [width]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            <span *ngIf="row.unlimitedSessions">Unlimited</span>
            <span *ngIf="!row.unlimitedSessions">{{row.sessionsRemaining}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Next Billing Date' | translate}}" [width]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            {{row.nextBillingDate}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Status' | translate}}" [width]="100">
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            <span *ngIf="row.status === 'PENDING_PAYMENT'">Pending Payment</span>
            <span *ngIf="row.status === 'ACTIVE'">Active</span>
            <span *ngIf="row.status === 'PAYMENT_FAILED'">Payment Failed</span>
            <span *ngIf="row.status === 'EXPIRED'">Expired</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <div class="flexbox-parent">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div style="text-align:center;">
            <button mat-raised-button color="accent" (click)="create()">{{'Assign to Plan' | translate}}</button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</div>
<mat-card appearance="outlined" class="panel new-card" *ngIf="!plans || plans.length === 0">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Plans"></app-zero-image><br>

          <div *ngIf="paymentSettings && paymentSettings.savvyPaySettings && paymentSettings.savvyPaySettings.accountHolderCode">
            <h5 class="ma-0">{{'This Customer does not have any Plans' | translate}}</h5><br>
            <!-- *ngIf="listEntityListDto.canCreate" -->
            <button mat-raised-button color="accent" (click)="create()">{{'Assign to Plan' | translate}}</button>
          </div>
          <div *ngIf="!paymentSettings || !paymentSettings?.savvyPaySettings || !paymentSettings?.savvyPaySettings?.accountHolderCode">
            <h3>Connect your account to <b>Savvy Pay</b> to start assigning <b>Plans</b> to customers</h3>
            <button mat-raised-button color="primary" (click)="goToSavvyPay()">{{'Connect to Savvy Pay' | translate}}</button>
          </div>

        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>