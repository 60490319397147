import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContextIdDto,
  ViewCustomerPlansDefinition
} from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';
import {ViewContextDto} from '@savvy/view-composite';
import {PlanDto, PlansCompService} from '@savvy/plan';
import {MatDialog} from '@angular/material/dialog';
import {AssignCustomerToPlanComponent} from './assignCustomerToPlan.component';
import {PaymentSettingsService} from '@savvy/payment';
import {PaymentSettings} from '@savvy/payment/model/models';
import {ViewPlanComponent} from '../shared/components/plan/view-plan.component';

@Component({
  selector: 'app-customer-plans',
  templateUrl: 'customerPlans.component.html'
})
export class CustomerPlansComponent {

  @Input() viewCustomerPlansDefinition: ViewCustomerPlansDefinition;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;

  plans: Array<PlanDto>;
  customerPlan = <PlanDto>{};
  selected: PlanDto[] = [];
  paymentSettings = <PaymentSettings>{};

  constructor(private router: Router,
              private nav: NavigateService,
              private dialog: MatDialog,
              private planCompApi: PlansCompService,
              private paymentSettingsApi: PaymentSettingsService
              ) {
  }

  ngOnInit() {
    console.log(this.viewCustomerPlansDefinition);
    this.loadSubscribedPlans();
    this.loadPaymentSettings();

  }


  create() {
    const dialogRef = this.dialog.open(AssignCustomerToPlanComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.viewContext.customerContextDto.customerId
      },
      width: '50%'
    });

    dialogRef.componentInstance.added.subscribe(data => {
      // do something
      this.loadSubscribedPlans();
    });
  }

  loadSubscribedPlans() {
    if (this.viewContext.customerContextDto && this.viewContext.customerContextDto.customerId) {
      this.planCompApi.loadCustomerPlans(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id)
          .subscribe(res => {
            this.plans = res.plans;
          });
    }
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.paymentSettings = response;
    });
  }

  onSelect(event) {
    console.log('Event: select', event, this.selected);
    this.customerPlan = this.selected[0];
    if (this.customerPlan && this.customerPlan.id) {
      const dialogRef = this.dialog.open(ViewPlanComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          plan: this.customerPlan
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadSubscribedPlans();
      });
    }

  }

  onActivate(event) {
    (event.type === 'click') && event.cellElement.blur();
  }

  goToSavvyPay() {
    this.router.navigate(['paymentSettings/paymentSettings',
      this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }
}

