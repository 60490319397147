import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddOnDefinition, AddOnDefinitionService, AddOnService, CreateAddOn } from '@savvy/add-ons';
import { AddOn } from '@savvy/add-ons/model/models';
import { OrgCompositeService } from '@savvy/org';
import { AffiliateService, LoadDiscountByCode, ReferralService } from '@savvy/referral';
import { RegistrationAttempt, RegistrationService } from '@savvy/registration';
import {
  CalculateDiscount, CalculateTax,
  ChangePlanSameCard,
  Coupon,
  CouponsService,
  LoadSubscriptionDefinitionsResponse,
  Subscription,
  SubscriptionCompService,
  SubscriptionContract,
  SubscriptionContractService,
  SubscriptionDefService,
  SubscriptionDefinition,
  SubscriptionDefinitionId,
  SubscriptionIntervalPackage,
  SubscriptionPaymentsService,
  SubscriptionService,
  TaxPayment
} from '@savvy/subscription';
import { UserDto } from '@savvy/ui';
import { ContextIdDto } from '@savvy/view-definition';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SignaturePad } from "angular-signature-pad-v2";
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GLOBAL } from 'src/app/app.constants';
import { ContextService } from 'src/app/flo/context.service';
import { NavigateService } from '../../../entry/NavigateService';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { CookiesService } from '../../services/cookies.service';
import { PermissionsHelperService } from '../../services/permissions-helper.service';
import { SubscriptionHelperService } from '../../services/subscription-helper.service';
import { TrialExpiredStateService } from '../../services/trial-expired-state.service';
import { UserCurrencyService } from '../../services/userCurrency.service';
import { SavvyPayComponent } from "../savvy-pay/savvy-pay.component";
import { ChangeCardComponent } from './change-card/change-card.component';
import { ConfirmAnnualPlanComponent } from './confirm-annual-plan/confirm-annual-plan.component';
import { ConfirmationSubscriptionModalComponent } from './confirm-modal/confirmation-subscription-modal.component';
import { ConfirmChangeSubscriptionPlanComponent } from './confirmChangeSubscriptionPlan.component';
import { SetQuantityModalComponent } from './set-quantity-modal.component';

export class AddOnDefToggle {
  addOnDef: AddOnDefinition;
  checked: boolean;
}
@Component({
  selector: 'app-select-subscription-plan',
  templateUrl: 'selectSubscriptionPlan.html',
  styleUrls: ['./selectSubscriptionPlan.component.scss']
})
export class SelectSubscriptionPlanComponent implements OnInit, OnDestroy {

  @ViewChild('cardPanel') cardPanel: MatSidenav;
  @ViewChild(SignaturePad) signaturePad: SignaturePad; // Enable the decorator when `angular2-signaturepad` library is activated
  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'canvasWidth': 400,
    'canvasHeight': 250
  };
  @Input() dialogParams: any;
  @Input() trialExpired = false;
  contextIdDto: ContextIdDto;
  response: LoadSubscriptionDefinitionsResponse;
  subscriptionDefinitionList: Array<SubscriptionDefinition> = [];
  subscription: Subscription;
  selectedSubscriptionDef: SubscriptionDefinition;
  selectedSubscriptionInterval: SubscriptionIntervalPackage;
  currencyCode;
  countryCode: string;
  showCreateSubscriptionPanel: boolean;
  showChangeCardPanel: boolean;
  showChangePlanPanel: boolean;
  userDto: UserDto;
  price;
  annual = true;
  autoRenew = true;

  addOnDefs: AddOnDefinition[] = [];
  addOns: Array<AddOn>;
  addOnDefToggle: Array<AddOnDefToggle> = [];

  stripeTest: UntypedFormGroup;
  showSpinner = false;

  elementsOptions: StripeElementsOptions = {
    // locale: 'en',
    locale: (<'auto'>this.translateService.currentLang),
  };

  stripeCardValid = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#000000',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '15px',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '::placeholder': {
          color: '#999999'
        }
      }
    }
  };

  currentSubscriptionDef: SubscriptionDefinition;
  currentSubscriptionInterval: SubscriptionIntervalPackage;

  registrationAttempts: Array<RegistrationAttempt>;

  selectedDiscountCode: Coupon = {} as Coupon;
  selectedReferralCode = '';
  validCoupon = false;
  validReferralCode = false;
  totalPrice = 0;
  totalDiscount = 0;
  totalReferralDiscount = 0;
  selectedAddOnDefs: AddOnDefinition[] = [];
  taxPayment: TaxPayment;
  subscriptionContract: SubscriptionContract;
  private sub: any;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private notify: FloSnackbarComponent,
    private subscriptionService: SubscriptionService,
    private subscriptionCompService: SubscriptionCompService,
    private trialExpiredStateService: TrialExpiredStateService,
    private dialog: MatDialog,
    private orgCompApi: OrgCompositeService,
    private navigateService: NavigateService,
    private translateService: TranslateService,
    private api: SubscriptionDefService,
    public deviceService: DeviceDetectorService,
    private addOnService: AddOnService,
    private addOnDefinitionService: AddOnDefinitionService,
    private registrationService: RegistrationService,
    public subscriptionHelperService: SubscriptionHelperService,
    private cookiesService: CookiesService,
    private couponsService: CouponsService,
    private subscriptionPaymentsService: SubscriptionPaymentsService,
    private referralService: ReferralService,
    private affiliateService: AffiliateService,
    private permissionsHelperService: PermissionsHelperService,
    private userCurrencyService: UserCurrencyService,
    private gtmService: GoogleTagManagerService,
    private subscriptionContractService: SubscriptionContractService,
    private contextService: ContextService,

  ) {
  }

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      coupon: ['', []],
      referralCode: ['', []],
      autoRenew: [true, []]
    });

    console.log('loading current subscription3');

    this.sub = this.route.params.subscribe(params => {
      const _params = (params['contextId'] ? params : this.dialogParams);
      this.contextIdDto = {
        contextIdType: _params['contextIdType'],
        contextId: _params['contextId']
      };
      this.loadCurrencyCode();
      this.subscriptionHelperService.checkSubscriptionStatus(this.contextIdDto, this.countryCode);
      this.loadOrg().then(res => {
        console.log('this.contextIdDto.contextIdType ' + this.contextIdDto.contextIdType);
        this.userDto = this.permissionsHelperService.getCurrentUser();

        this.api.loadSubscriptionDefsForUsersAppType(
          this.contextIdDto.contextIdType,
          this.contextIdDto.contextId).subscribe(subDefs => {
            this.subscriptionDefinitionList = subDefs.subscriptionDefinitionList;
            this.subscriptionHelperService.loadCurrent(this.contextIdDto).then((sub: Subscription) => {
              this.subscription = sub;
              this.loadSubscriptionContract();
              if (this.subscription.subscriptionInterval === Subscription.SubscriptionIntervalEnum.Annual) {
                this.annual = true;
              } else {
                this.annual = false;
              }
              this.getCurrentSubscription();
            });
            if (this.userDto?.employerOrgId) {
              this.registrationService.listAttemptsByOrgId(this.userDto.employerOrgId).subscribe(res => {
                this.registrationAttempts = res.registrationAttempts;
              });
            }

          });

        this.loadAddOns();
      });
    });
  }

  loadSubscriptionContract() {
    this.subscriptionContractService.loadOrCreateSubscriptionContract(this.contextIdDto.contextId,
      this.contextService.getAppType(), this.countryCode, this.subscription.id).subscribe(res => {
        if (res) {
          this.subscriptionContract = res;
        }
      });
  }

  loadReferralCode() {
    this.subscriptionService.subscriptionTrialStatus(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res && res?.referralCode) {
        this.selectedReferralCode = res.referralCode;
        this.stripeTest.patchValue({
          referralCode: this.selectedReferralCode
        }, { emitEvent: true });
        this.referralCodeChanged(this.selectedReferralCode);
      }
      if (res && res?.affiliateCode) {
        this.selectedReferralCode = res.affiliateCode;
        this.stripeTest.patchValue({
          referralCode: this.selectedReferralCode
        }, { emitEvent: true });
        this.referralCodeChanged(this.selectedReferralCode);
      }
      this.calculateTotalPayable();
    });
  }

  getAddOnPrice(addOnDef: AddOnDefinition) {
    return addOnDef.addOnCurrencies.find(c => c.currency === this.currencyCode)?.cost || 0.00;
  }

  getCurrentSubscription() {
    if (this.subscriptionDefinitionList) {
      for (const subDef of this.subscriptionDefinitionList) {
        if (this.subscriptionHelperService.currentPlan(subDef)) {
          this.currentSubscriptionDef = subDef;
          for (const subInterval of subDef.subscriptionIntervalPackages) {
            if (this.subscription?.subscriptionInterval === subInterval?.subscriptionInterval) {
              this.currentSubscriptionInterval = subInterval;
              break;
            }
          }
          break;
        }
      }
      const filteredSubDefs = [];
      for (const subscriptionDefinition of this.subscriptionDefinitionList) {
        if (!subscriptionDefinition.legacy) {
          filteredSubDefs.push(subscriptionDefinition);
        } else if (this.currentSubscriptionDef && subscriptionDefinition.legacy
          && subscriptionDefinition?.id === this.currentSubscriptionDef?.id) {
          filteredSubDefs.push(subscriptionDefinition);
        }
      }
      this.subscriptionDefinitionList = _.cloneDeep(filteredSubDefs);
    }
  }

  loadAddOnDefs() {
    console.log('loading addon def');
    this.addOnDefinitionService.load().subscribe(response => {
      this.addOnDefs = response;
      console.log('response');
      console.log(response);
      this.addOnDefs.forEach(addOnDef => {
        const item = {
          addOnDef,
          checked: this.isEnabled(addOnDef)
        };

        this.addOnDefToggle.push(item);

        this.addOnDefToggle = [...this.addOnDefToggle];
      });
    });
  }

  planTypeChanged() {
    if (this.annual) {
      this.confirmAnnualSubscription();

    } else {
      if (this.subscription) {
        this.subscription.subscriptionInterval = Subscription.SubscriptionIntervalEnum.Monthly;
      }
    }
  }

  confirmAnnualSubscription() {
    const dialogRef = this.dialog.open(ConfirmAnnualPlanComponent, {
      // data: {
      //   contextIdDto: this.contextIdDto,
      //   subscription: this.subscription
      // },
      // autoFocus: false
      height: 'auto',
      maxWidth: '450px',
      width: '100%',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.annual = true;
        this.subscription.subscriptionInterval = Subscription.SubscriptionIntervalEnum.Annual;
      } else {
        this.annual = false;
        this.subscription.subscriptionInterval = Subscription.SubscriptionIntervalEnum.Monthly;
      }
    });
  }

  loadCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;

      });
  }

  getPeriod() {
    if (this.annual) {
      return 'year';
    } else {
      return 'month';
    }
  }

  getCurrencyCode(pkg: SubscriptionIntervalPackage): string {
    let cost = -1;
    if (pkg) {
      pkg.subscriptionCurrencies.forEach(subCurr => {
        if (this.currencyCode === subCurr.currency) {
          cost = subCurr.cost;
        }
      });
    }

    if (cost === -1) {
      this.currencyCode = 'GBP';
      return 'GBP';
    }
    return this.currencyCode;
  }

  getCost(pkg: SubscriptionIntervalPackage): number {
    let cost = -1;

    if (pkg) {
      pkg.subscriptionCurrencies.forEach(subCurr => {
        if (this.currencyCode === subCurr.currency) {
          cost = subCurr.cost;
        }
      });

      if (cost === -1) {
        return this.getDefaultCost(pkg);
      }
    }
    return cost;
  }

  getLocationCost(pkg: SubscriptionIntervalPackage): number {
    let cost = -1;
    pkg.subscriptionCurrencies.forEach(subCurr => {
      if (this.currencyCode === subCurr.currency) {
        cost = subCurr.additionalLocationCost;
      }
    });

    if (cost === -1) {
      return this.getDefaultLocationCost(pkg);
    }
    return cost;
  }

  getDefaultLocationCost(pkg: SubscriptionIntervalPackage): number {
    let cost = -1;
    pkg.subscriptionCurrencies.forEach(subCurr => {
      if ('USD' === subCurr.currency.currencyCode) {
        cost = subCurr.additionalLocationCost;
      }
    });
    return cost;
  }

  getDefaultCost(pkg: SubscriptionIntervalPackage): number {
    let cost = -1;
    pkg.subscriptionCurrencies.forEach(subCurr => {
      if ('USD' === subCurr.currency.currencyCode) {
        cost = subCurr.cost;
      }
    });
    return cost;
  }

  display(subscriptionInterval: SubscriptionIntervalPackage.SubscriptionIntervalEnum) {
    if (subscriptionInterval === SubscriptionIntervalPackage.SubscriptionIntervalEnum.Annual
      && this.annual) {
      return true;
    }
    if (subscriptionInterval === SubscriptionIntervalPackage.SubscriptionIntervalEnum.Monthly
      && !this.annual) {
      return true;
    }
    return false;
  }

  getCurrentTitle(): string {
    let subDefHeader;
    if (this.subscriptionDefinitionList) {
      for (const subDef of this.subscriptionDefinitionList) {
        subDefHeader = this.subscriptionHelperService.getTitle(subDef);
        if (subDefHeader) {
          break;
        }
      }
    }
    return subDefHeader;
  }

  subscriptionCreated(subscription: Subscription) {
    console.log('created subscription');
    this.subscriptionHelperService.loadCurrent(this.contextIdDto);
    this.subscription = subscription;
    this.subscriptionHelperService.subscription = this.subscription;
    this.translateService.get('Your subscription is now active!')
      .subscribe(val => {
        this.notify.message = val;
        this.notify.open();
      });
    this.closePanel();
    this.navigateService.navigateHome(this.contextIdDto.contextId);
  }

  // cardChanged(subscription: Subscription) {
  //   console.log('created subscription');
  //   this.subscriptionHelperService.loadCurrent(this.contextIdDto).then(sub => {
  //     this.subscription = subscription;
  //     this.subscriptionHelperService.subscription = this.subscription;
  //     this.translateService.get('Your card has been changed!')
  //       .subscribe(val => {
  //         this.notify.message = val;
  //         this.notify.open();
  //       });
  //     this.closePanel();
  //   });
  // }

  openPanelToChangeCard() {
    this.showChangeCardPanel = true;
    this.showCreateSubscriptionPanel = false;
    this.showChangePlanPanel = false;
    this.cardPanel.open();
  }

  openPanelToChangePlan() {
    this.showChangeCardPanel = false;
    this.showCreateSubscriptionPanel = false;
    this.showChangePlanPanel = true;
    this.cardPanel.open();
  }

  openPanelToCreateSubscription() {
    console.log('openPanelToCreateSubscription');
    this.showChangeCardPanel = false;
    this.showChangePlanPanel = false;
    this.showCreateSubscriptionPanel = true;
    this.cardPanel.open();
  }

  closePanel() {
    this.cardPanel.close();
    this.showChangeCardPanel = false;
    this.showCreateSubscriptionPanel = false;
    this.showChangePlanPanel = false;
  }

  planChanged(subscription: Subscription) {
    console.log('plan changed');
    this.subscriptionHelperService.loadCurrent(this.contextIdDto);
    this.subscription = subscription;
    this.subscriptionHelperService.subscription = this.subscription;
    this.translateService.get('Your plan has been changed!')
      .subscribe(val => {
        this.notify.message = val;
        this.notify.open();
      });
    this.closePanel();
  }

  cancelPlan() {
    console.log('cancel plan');

    const cancel = <any>{};
    cancel.contextIdDto = this.contextIdDto;
    cancel.subscriptionId = this.subscription.subscriptionId;

    // this.subscriptionCompService.cancelSubscription(
    //   this.subscription.subscriptionId.toString(),
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType)
    //   .subscribe(() => {
    //     // response;
    //     this.translateService.get('Successfully Cancelled Plan')
    //       .subscribe(val => {
    //         this.notify.message = val;
    //         this.notify.open();
    //         this.router.navigate(['/subscription/subscriptions',
    //           this.contextIdDto.contextId,
    //           this.contextIdDto.contextIdType]);
    //       });
    //     this.subscriptionHelperService.loadCurrent(this.contextIdDto);
    //   });
  }

  newPlanSelected(subscriptionDef: SubscriptionDefinition) {
    console.log('selectSubPlan:planSelected ' + subscriptionDef.id);
    if (!this.currencyCode) {
      console.log('ERROR in planSelected and no currency settings - HOW DID THIS HAPPEN?');
      return;
    }
    this.selectedSubscriptionDef = subscriptionDef;
    this.setPrice();
    this.openPanelToCreateSubscription();
  }

  locationManagement() {
    const dialogRef = this.dialog.open(SetQuantityModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        subscription: this.subscription
      },
      autoFocus: false,
      height: 'auto',
      maxWidth: '450px',
      width: '100%',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.subscriptionHelperService.loadCurrent(this.contextIdDto).then((sub: Subscription) => {
        this.subscription = sub;
      });
    });
  }

  changeCard(subscriptionDef: SubscriptionDefinition) {
    // this.sub;
    this.selectedSubscriptionDef = subscriptionDef;
    console.log('changeCard ', subscriptionDef);
    if (!this.currencyCode) {
      console.log('ERROR in planSelected and no currency settings - HOW DID THIS HAPPEN?');
      return;
    }
    this.setPrice();
    this.openPanelToChangeCard();
  }


  setPrice() {
    if (this.selectedSubscriptionDef && this.selectedSubscriptionDef.subscriptionIntervalPackages) {
      this.price = this.getCost(this.getAnnualPackage(this.selectedSubscriptionDef.subscriptionIntervalPackages));

      if (!this.annual) {
        this.price = this.getCost(this.getMonthlyPackage(this.selectedSubscriptionDef.subscriptionIntervalPackages));
      }

      const req = <CalculateTax>{};
      req.contextIdDto = this.contextIdDto;
      req.subscriptionInterval = this.subscription.subscriptionInterval;
      req.subscriptionDefinitionId = this.selectedSubscriptionDef.id;
      req.currencyCode = this.currencyCode;
      req.countryCode = this.countryCode;

      this.subscriptionPaymentsService.calculateTax(req).subscribe(res => {
        this.taxPayment = res;
        this.loadReferralCode();
      });


    } else {
      this.price = null;
    }

  }

  getPriceForChangeCard(subscriptionDef: SubscriptionDefinition) {
    this.price = this.getCost(this.getAnnualPackage(subscriptionDef.subscriptionIntervalPackages));

    if (!this.annual) {
      this.price = this.getCost(this.getMonthlyPackage(subscriptionDef.subscriptionIntervalPackages));
    }

    const req = <CalculateTax>{};
    req.contextIdDto = this.contextIdDto;
    req.subscriptionInterval = this.subscription.subscriptionInterval;
    req.subscriptionDefinitionId = subscriptionDef.id;
    req.currencyCode = this.currencyCode;
    req.countryCode = this.countryCode;

    this.subscriptionPaymentsService.calculateTax(req).subscribe(res => {
      this.taxPayment = res;
      this.loadReferralCode();
    });


  }

  changePlanNoCardSpecifiedYet(subscriptionDef: SubscriptionDefinition) {
    this.selectedSubscriptionDef = subscriptionDef;
    this.setPrice();
    this.openPanelToChangePlan();
  }

  updateSubscription() {

  }

  choosePlan(subscriptionDef: SubscriptionDefinition, pkg: SubscriptionIntervalPackage) {
    this.selectedSubscriptionDef = subscriptionDef;
    this.selectedSubscriptionInterval = pkg;
    this.setPrice();
  }

  backToPlanList() {
    this.selectedSubscriptionDef = undefined;
    this.selectedSubscriptionInterval = undefined;
  }

  changePlanSameCardSelected(subscriptionDef: SubscriptionDefinition, pkg: SubscriptionIntervalPackage) {
    console.log('Just need to update the plan');
    if (!this.subscription.subscriptionId) {
      console.log('no current subscription');
      return;
    }

    this.subscriptionHelperService.checkSubscriptionStatus(this.contextIdDto, this.countryCode)
      .then(res => {
        const skipCardVerification = this.cookiesService.getCookie('skipCardVerification');
        if (res && !skipCardVerification) {
          const newCost = this.getCost(pkg);
          const currentCost = this.getCost(this.currentSubscriptionInterval);

          const dialogRef = this.dialog.open(ConfirmChangeSubscriptionPlanComponent, {
            data: {
              annual: this.annual,
              subscriptionDef,
              currencyCode: this.currencyCode,
              cost: this.getCost(pkg),
              upgrade: newCost > currentCost
            },
            height: 'auto',
            width: '500px',
            panelClass: 'helpwindow'
          });

          dialogRef.afterClosed().subscribe(response => {
            console.log(response);
            if (response.confirm) {
              this.showCreateSubscriptionPanel = false;
              this.subscription.subscriptionDefinitionId = <SubscriptionDefinitionId>{};
              this.subscription.subscriptionDefinitionId.id = subscriptionDef.id;

              const req = <ChangePlanSameCard>{};
              req.subscriptionDefinitionId = <SubscriptionDefinitionId>{};
              req.subscriptionDefinitionId.id = subscriptionDef.id;
              req.couponId = response.couponId;

              if (!this.subscription.subscriptionId) {
                console.log('ERROR, no subscription id');
                return;
              }
              req.subscriptionId = this.subscription.subscriptionId;
              if (this.annual) {
                req.subscriptionInterval = ChangePlanSameCard.SubscriptionIntervalEnum.Annual;
              } else {
                req.subscriptionInterval = ChangePlanSameCard.SubscriptionIntervalEnum.Monthly;
              }
              req.contextIdDto = this.contextIdDto;
              this.subscriptionCompService.changePlanSameCard(req).subscribe(response => {
                if (response.subscriptionError) {
                  this.notify.message = 'Card Declined';
                  this.notify.open();
                } else {
                  this.subscription = response.subscription;
                  this.subscriptionHelperService.loadCurrent(this.contextIdDto);
                  this.trialExpiredStateService.reloadState(this.contextIdDto, this.subscriptionCompService);
                  this.translateService.get('Successfully Updated Plan')
                    .subscribe(val => {
                      this.notify.message = val;
                      this.notify.open();
                      // Navigate
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    });
                }
              });
            }
            console.log('closed');
          });
        } else {
          this.notify.message = 'Please verify your card details first to change the plan';
          this.notify.open();
          this.cookiesService.deleteCookie('skipCardVerification');
        }
      });


  }

  getMonthlyPackage(subscriptionIntervalPackages): SubscriptionIntervalPackage {
    return subscriptionIntervalPackages
      .find(a => a.subscriptionInterval === SubscriptionIntervalPackage.SubscriptionIntervalEnum.Monthly);
  }

  getAnnualPackage(subscriptionIntervalPackages): SubscriptionIntervalPackage {
    return subscriptionIntervalPackages
      .find(a => a.subscriptionInterval === SubscriptionIntervalPackage.SubscriptionIntervalEnum.Annual);
  }

  getMonthlyCost(yearlyCost) {
    return (yearlyCost / 12).toFixed(2);
  }

  ngOnDestroy(): void {

  }

  loadAddOns() {
    console.log('loading addons');
    this.addOnService.loadByEnv(this.contextIdDto.contextId).subscribe(response => {
      this.addOns = response;

      this.loadAddOnDefs();
    });
  }

  isEnabled(addOnDef: AddOnDefinition) {
    let checked = false;
    if (this.addOns) {
      this.addOns.forEach(addOn => {
        if (addOn.addOnDefinitionId === addOnDef.id) {
          checked = true;
        }
      });
    }
    return checked;
  }

  getAddOnCost(): number {
    let cost = 0;
    this.addOnDefToggle.forEach(item => {
      if (item.checked && item.addOnDef.addOnCurrencies) {
        item.addOnDef.addOnCurrencies.forEach(addOnCurrency => {
          if (addOnCurrency.currency === this.currencyCode) {
            cost = cost + addOnCurrency.cost;
          }
        });
      }
    });

    if (this.annual) {
      return cost * 12;
    }
    return cost;
  }

  getNumberOfAddOns(): number {
    let count = 0;
    this.addOnDefToggle.forEach(item => {
      if (item.checked && item.addOnDef.addOnCurrencies) {

        item.addOnDef.addOnCurrencies.forEach(addOnCurrency => {
          if (addOnCurrency.currency === this.currencyCode) {
            count++;
          }

        });
      }
    });

    return count;
  }

  calculateTotalPayable() {
    const taxAmount = this.taxPayment ? this.taxPayment.amount : 0;
    this.totalPrice = (this.getCost(this.selectedSubscriptionInterval) + this.getAddOnCost() + taxAmount) - this.totalDiscount - this.totalReferralDiscount;
    this.totalPrice = Number(this.totalPrice.toFixed(2));
    this.selectedAddOnDefs = this.addOnDefToggle.filter(adt => adt.checked).map(ad => ad.addOnDef);
  }

  buy() {
    this.setPrice();
    const confirmDialog = this.dialog.open(ConfirmationSubscriptionModalComponent, {
      data: {
        message: this.getMessage()
      },
      autoFocus: false
    });

    confirmDialog.afterClosed()
      .subscribe(
        res => {
          if (res) {
            this.buySubscription();
          }
        });

  }

  buySubscription() {

    console.log('inside buy');
    if (this.validForm()) {

    }
    this.showSpinner = true;
    // const name = this.stripeTest.get('name').value;
    // const coupon = this.stripeTest.get('coupon').value;
    // const referralCode = this.stripeTest.get('referralCode').value;

    // this.stripeService
    //   .createToken(this.card.element, { name })
    //   .subscribe(result => {
    //     if (result.token) {
    //       // Use the token to create a charge or a customer
    //       // https://stripe.com/docs/charges
    //       console.log('Got token ' + result.token.id);

    //       const subscription = <Subscription>{};
    //       subscription.subscriptionDefinitionId = this.selectedSubscriptionDef.subscriptionDefinitionId;
    //       subscription.cardToken = result.token.id;
    //       subscription.coupon = coupon;
    //       subscription.referralCode = referralCode;

    //       if (this.annual) {
    //         subscription.subscriptionInterval = Subscription.SubscriptionIntervalEnum.Annual;
    //       } else {
    //         subscription.subscriptionInterval = Subscription.SubscriptionIntervalEnum.Monthly;

    //       }
    //       subscription.autoRenew = this.autoRenew;
    //       subscription.currencyCode = this.currencyCode;
    //       subscription.current = true;
    //       subscription.quantity = 1;
    //       subscription.userId = this.userDto.userId;
    //       subscription.ownerId = this.contextIdDto.contextId;
    //       subscription.cost = this.price;
    //       console.log('creating subscription');
    //       const req = <CreateSubscriptionComp>{};
    //       req.subscription = subscription;
    //       req.contextId = this.contextIdDto.contextId;
    //       req.contextIdType = this.contextIdDto.contextIdType;

    //       this.subscriptionCompService.createSubscription(req).subscribe(response => {
    //         this.showSpinner = false;
    //         if (response.errorMessage) {
    //           this.notify.message = response.errorMessage;
    //           this.notify.open();
    //         } else {
    //           this.sendEventToGtagIfAny();
    //           this.saveAddOns();
    //           // this.showSuccessModal();

    //           this.subscriptionHelperService.loadCurrent(this.contextIdDto);
    //           this.subscriptionHelperService.checkSubscriptionStatus(this.contextIdDto, this.countryCode);
    //           // this.backToPlanList();
    //           this.router.navigate(['/subscription/subscriptions/success',
    //             this.contextIdDto.contextId,
    //             this.contextIdDto.contextIdType]);
    //           // this.subscriptionCreated.emit(response.subscription);
    //         }
    //       });
    //     } else if (result.error) {
    //       // Error creating the token
    //       console.log(result.error.message);
    //       this.showSpinner = false;
    //     }
    //   });
  }

  sendEventToGtagIfAny() {
    for (const reg of this.registrationAttempts) {
      if (reg.utmCampaign || reg.utmMedium || reg.utmContent || reg.utmTerm || reg.utmSource || reg.utmAgid) {
        const param = {
          utmCampaign: reg.utmCampaign,
          utmMedium: reg.utmMedium,
          utmContent: reg.utmContent,
          utmTerm: reg.utmTerm,
          utmSource: reg.utmSource,
          utmAgid: reg.utmAgid,
          device: reg.device,
          placement: reg.placement,
          creative: reg.creative
        };
        console.log('sending to gtag');
        console.log(param);
        // this.gtag.event('subscribe', param);

        // this.gtmService.pushTag(gtmTag);

        const userData = {
          email: this.userDto.email,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          phone_number: this.userDto.phoneNumber,
          address: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            first_name: this.userDto.firstName,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            last_name: this.userDto.lastName,
            street: '',
            city: '',
            region: '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            postal_code: '',
            country: ''
          }
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // this.gtag.set({ user_data: userData });
        const gtmTag = {
          event: 'subscribe',
          param,
          userData
        };
        this.gtmService.pushTag(gtmTag);

      }

    }
  }

  showSuccessModal() {
  }

  getReturnUrl(contextIdDto: ContextIdDto) {
    if (GLOBAL.localhost === 'localhost') {
      return 'http://' + GLOBAL.localhost + ':4200/subscription/subscriptions/success/'
        + contextIdDto.contextId + '/' + contextIdDto.contextIdType;
    } else {
      return 'https://' + GLOBAL.localhost + '/subscription/subscriptions/success/'
        + contextIdDto.contextId + '/' + contextIdDto.contextIdType;
    }
  }

  getFailedReturnUrl(contextIdDto: ContextIdDto) {
    if (GLOBAL.localhost === 'localhost') {
      return 'http://' + GLOBAL.localhost + ':4200/subscription/subscriptions/failed/'
        + contextIdDto.contextId + '/' + contextIdDto.contextIdType;
    } else {
      return 'https://' + GLOBAL.localhost + '/subscription/subscriptions/failed/'
        + contextIdDto.contextId + '/' + contextIdDto.contextIdType;
    }
  }

  getReturnUrlForChangeCard(contextIdDto: ContextIdDto) {
    if (GLOBAL.localhost === 'localhost') {
      return 'http://' + GLOBAL.localhost + ':4200/subscription/subscriptions/cardChanged/'
        + contextIdDto.contextId + '/' + contextIdDto.contextIdType;
    } else {
      return 'https://' + GLOBAL.localhost + '/subscription/subscriptions/cardChanged/'
        + contextIdDto.contextId + '/' + contextIdDto.contextIdType;
    }
  }

  changeCardModal() {

    if (this.subscriptionHelperService.subscriptionStatusDto.subscriptionStatus === 'CANCELLED') {
      console.log("subscription is cancelled");
      this.getPriceForChangeCard(this.currentSubscriptionDef);
    }
    console.log("Opening modal");
    console.log(this.price);
    const data = {
      contextIdDto: this.contextIdDto,
      subscriptionId: this.subscription.subscriptionId,
      selectedSubscriptionDef: this.selectedSubscriptionDef,
      annual: this.annual,
      currencyCode: this.currencyCode,
      price: this.price ? this.price : 0,
      subscription: this.subscription,
      countryCode: this.countryCode,
      returnUrl: this.getReturnUrlForChangeCard(this.contextIdDto)
    };
    console.log('data');
    console.log(data);
    const dialogRef = this.dialog.open(ChangeCardComponent, {
      data,
      width: '100%',
      maxWidth: '450px',
      maxHeight: '600px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.subscriptionHelperService.loadCurrent(this.contextIdDto);
      this.subscriptionHelperService.checkSubscriptionStatus(this.contextIdDto, this.countryCode);
    });
  }

  saveAddOns() {
    this.addOnDefToggle.forEach(addOnDefToggleItem => {
      if (addOnDefToggleItem.checked) {
        const addOnDefItem = addOnDefToggleItem.addOnDef;

        const addOn = <CreateAddOn>{};
        addOn.addOnDefinitionId = addOnDefItem.id;
        addOn.contextIdDto = this.contextIdDto;

        console.log('savy addon');
        console.log(addOn);
        // this.addOnCompService.createAddOn(addOn).subscribe(response => {
        //
        //   // this.loadAddOns();
        // }, error => {
        //   console.log('error', error);
        //   this.notify.message = 'Failed to add add-on, please update your card';
        //   this.notify.open();
        // });
      }
    });


  }

  validForm() {
    return this.stripeTest.valid;
  }

  getAnnualLabel() {
    if (this.annual) {
      return 'Annual ';
    } else {
      return 'Monthly ';
    }
  }

  getMessage() {
    if (this.annual) {
      return 'You are subscribing to an <b>' + this.getAnnualLabel()
        + this.selectedSubscriptionDef.name + '</b> plan for ' + this.currencyCode + ' ' + this.price + ', are you sure?';
    } else {
      return 'You are subscribing to an <b>' + this.getAnnualLabel()
        + this.selectedSubscriptionDef.name + '<b> plan for ' + this.currencyCode + ' ' + this.price + ' per month, are you sure?';
    }
  }

  getCardWidth() {
    if (this.subscriptionDefinitionList?.length === 3) {
      return 31.2;
    } else if (this.subscriptionDefinitionList?.length === 5) {
      return 19.2;
    } else {
      return 24.2;
    }
  }

  getSmsCredits(intervalPackage: SubscriptionIntervalPackage, subDef: SubscriptionDefinition) {

    let maxSmsCredits = subDef.subscriptionPackage.maxNumSmsCredits;

    if (intervalPackage) {
      intervalPackage.subscriptionCurrencies.forEach(sc => {
        if (sc.maxNumSmsCreditsCountryOverrides) {
          if (sc.maxNumSmsCreditsCountryOverrides[this.countryCode]) {
            maxSmsCredits = sc.maxNumSmsCreditsCountryOverrides[this.countryCode];
          }
        } else if (sc.currency === this.currencyCode && sc.maxNumSmsCredits) {
          maxSmsCredits = sc.maxNumSmsCredits;
        }
      });
    }
    return maxSmsCredits;
  }

  loadOrg() {
    return new Promise((resolve) => {
      this.orgCompApi.getCurrentOrgComp().subscribe(res => {
        this.countryCode = res.countryCode;
        resolve(res);
        console.log('country code');
        console.log(this.countryCode);
      });
    });
  }

  discountCodeChanged() {
    const coupon = this.stripeTest.get('coupon').value;
    if (coupon) {
      this.couponsService.searchCouponByCode(coupon).subscribe(res => {
        if (res && res.couponCode === coupon) {
          this.validCoupon = true;
          this.selectedDiscountCode = res;
          this.getTotalPrice(res);
        } else {
          this.validCoupon = false;
          this.selectedDiscountCode = {} as Coupon;
          this.totalDiscount = 0;
          this.calculateTotalPayable();
        }
      });
    } else {
      this.calculateTotalPayable();
    }

  }

  referralCodeChanged(value: any) {
    const referralCode = this.stripeTest.get('referralCode').value;
    const discount = 0.1;
    if (referralCode.indexOf('AFF_') === -1) {
      this.referralService.referralCodeExists(referralCode).subscribe(res => {
        if (res && res.codeExists) {

          this.validReferralCode = true;
          this.selectedReferralCode = referralCode;

          let subscriptionInterval = 'MONTHLY';
          if (this.annual) {
            subscriptionInterval = 'ANNUAL'
          }

          const taxAmount = this.taxPayment ? this.taxPayment.amount : 0;
          const totalPrice = (this.getCost(this.selectedSubscriptionInterval) + this.getAddOnCost() + taxAmount) - this.totalDiscount - this.totalReferralDiscount;

          const req = <LoadDiscountByCode>{};
          req.subDefId = this.selectedSubscriptionDef.id;
          req.subscriptionInterval = subscriptionInterval;
          req.code = referralCode;
          req.currencyCode = this.currencyCode;
          req.amount = totalPrice;

          this.referralService.loadDiscountByCode(req).subscribe(res => {
            if (res > 0) {
              this.totalReferralDiscount = res;
            } else {
              this.totalReferralDiscount = Number((this.totalPrice * discount).toFixed(2));
            }
            this.calculateTotalPayable();
          });

        } else {
          this.validReferralCode = false;
          this.selectedReferralCode = null;
          this.totalReferralDiscount = 0;
          this.calculateTotalPayable();
        }

      });
    }
    if (referralCode.indexOf('AFF_') > -1) {
      this.affiliateService.isFirstAffiliateSignup(referralCode, this.contextIdDto.contextId).subscribe(res => {
        if (res) {
          this.validReferralCode = true;
          this.selectedReferralCode = referralCode;
          this.totalReferralDiscount = this.totalPrice * .25;
        } else {
          this.validReferralCode = false;
          this.selectedReferralCode = null;
          this.totalReferralDiscount = 0;
          this.stripeTest.patchValue({
            referralCode: ''
          });
        }
        this.calculateTotalPayable();
      });
    }


  }

  getTotalPrice(coupon?: Coupon) {
    const calculateDiscount: CalculateDiscount = {
      contextIdDto: this.contextIdDto,
      couponCode: coupon?.couponCode,
      couponId: coupon?.id,
      currencyCode: this.currencyCode,
      subscriptionDefinitionId: this.selectedSubscriptionDef.id,
      subscriptionInterval: this.subscription.subscriptionInterval
    };
    this.couponsService.calculateDiscount(calculateDiscount).subscribe(res => {
      this.totalPrice = res.totalPayable + this.getAddOnCost();
      this.totalDiscount = res.totalDiscount;
    });
  }

  updateSubscriptionContract() {
    if (this.subscriptionContract?.signature) {
      this.subscriptionContract.dateSigned = new Date().toISOString();
    }
    this.subscriptionContractService.update1(this.subscriptionContract).subscribe(res => {
      if (res) {
        console.log('Sub contarct signed', res);
      }
    });
  }

  proceedToPayment() {
    this.updateSubscriptionContract();
    const coupon = this.stripeTest.get('coupon').value;
    if (coupon) {
      this.couponsService.searchCouponByCode(coupon).subscribe(res => {
        if (res && res.couponCode === coupon) {
          this.validCoupon = true;
          this.selectedDiscountCode = res;
          const calculateDiscount: CalculateDiscount = {
            contextIdDto: this.contextIdDto,
            couponCode: this.selectedDiscountCode?.couponCode,
            couponId: this.selectedDiscountCode?.id,
            currencyCode: this.currencyCode,
            subscriptionDefinitionId: this.selectedSubscriptionDef.id,
            subscriptionInterval: this.subscription.subscriptionInterval
          };
          this.couponsService.calculateDiscount(calculateDiscount).subscribe(dis => {
            this.totalPrice = dis.totalPayable + this.getAddOnCost();
            this.totalDiscount = dis.totalDiscount;
            this.openSavvyPay();
          });

        } else {
          this.validCoupon = false;
          this.selectedDiscountCode = {} as Coupon;
          this.totalDiscount = 0;
          this.calculateTotalPayable();
          this.openSavvyPay();
        }
      });
    } else {
      this.calculateTotalPayable();
      this.openSavvyPay();
    }


  }

  openSavvyPay() {
    const dialogRef = this.dialog.open(SavvyPayComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        subscription: this.subscription,
        cost: this.totalPrice,
        subscriptionDef: this.selectedSubscriptionDef,
        currency: this.currencyCode,
        discountCoupon: this.selectedDiscountCode,
        referralCode: this.selectedReferralCode,
        countryCode: this.countryCode,
        autoRenew: this.autoRenew,
        returnUrl: this.getReturnUrl(this.contextIdDto),
        failedReturnUrl: this.getFailedReturnUrl(this.contextIdDto),
        selectedAddOnDefs: this.selectedAddOnDefs,
        planAmount: this.getCost(this.selectedSubscriptionInterval),
        taxPayment: this.taxPayment

      },
      width: '720px',
      panelClass: ['scrollable-modal', 'helpwindow']
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.loadPlanDefinitions();
    });
  }

  // Enable the function when `angular2-signaturepad` library is activated
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
    this.subscriptionContract.signature = this.signaturePad.toDataURL();
    console.log(this.subscriptionContract.signature);
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  // Enable the function when `angular2-signaturepad` library is activated
  clearSignPad() {
    this.signaturePad.clear();
    this.subscriptionContract.signature = '';
  }

  // Enable the function when `angular2-signaturepad` library is activated
  resizeSignaturePad() {
    this.signaturePad.set('canvasWidth', document.getElementById('sign_contract_canvas').offsetWidth);
    this.signaturePad.clear();
  }


}
