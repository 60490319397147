import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {ContextService} from '../context.service';
import {Router} from '@angular/router';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import {WindowRef} from '../windowWrapper';
import {TranslateService} from '@ngx-translate/core';
import {ContextIdDto} from '@savvy/menu';
import {Subscription} from 'rxjs';
import {PaymentSettings} from '@savvy/payment/model/models';
import {PaymentSettingsService} from '@savvy/payment';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-payment-plans',
  templateUrl: './paymentPlans.component.html',
  styleUrls: ['paymentPlans.component.scss']
})
export class PaymentPlansComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;

  subscriptions: Subscription[] = [];
  paymentSettings = <PaymentSettings>{};
  isModal = false;

  constructor(
    private router: Router,
    private windowRef: WindowRef,
    private translate: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private contextService: ContextService,
    private paymentSettingsApi: PaymentSettingsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any,) {
      if (this.data) {
        this.isModal = this.data.isModal;
      }
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadPaymentSettings();
      }
    }));
  }

  goToSavvyPay() {
    this.router.navigate(['paymentSettings/paymentSettings',
      this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
      this.paymentSettings = response;
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
